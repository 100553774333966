<article class="dialog__header mat-dialog-title" *ngIf="!showViewTemplate">
    <h4 mat-dialog-title>Tasks </h4>
    <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
</article>
<article class="dialog__header mat-dialog-title" *ngIf="showViewTemplate">
    <h4 mat-dialog-title>Task Notes</h4>
    <img mat-dialog-close class="dialog__header-icon" src="assets/icons/icon-close-dark.svg" alt="close icon" />
</article>

<mat-dialog-content class="mat-typography mat-dialog-content" *ngIf="!showViewTemplate">
    <section class="file__search__subnav">
        <div class="file__search__subnav__breadcrumb">
            <img name="left-arrow" mat-dialog-close class="left-arrow" alt=""
                src="./assets/icons/icon-arrow-left-dark.svg" />
            <span class="breadcrumb" name="breadcrumb">
                &nbsp;Task(s)
            </span>
        </div>
    </section>
    <div class="headertext">
        <span>Existing {{data.psName}} tasks found for this file for {{data.processname}}</span>
    </div>
    <table mat-table [dataSource]="dataSource" aria-hidden="true">
        <!-- <ng-container *ngIf="dataModel && dataModel.columns">
            <div *ngFor="let disCol of dataModel?.columns">

                <ng-container [matColumnDef]="disCol.columnName">
                    <mat-header-cell *matHeaderCellDef>
                        <span>
                            {{ disCol.displayName }}
                        </span>
                    </mat-header-cell>
                    <mat-cell (click)="highlight(row)" *matCellDef="let row;;index as i;"
                        [ngClass]="{'highlight': selectedRowIndex == row.id}">
                        <span class="edit-icon cr-pointer" *ngIf="disCol?.type === 'view'" id="btnView"
                            (click)="viewNotes(row)"><span class="viewstyle">View</span>&nbsp; <img
                                src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxOCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjE4MjUgNi4zODY4M0wxMi4wMzY1IDExLjc4ODlDMTEuOTg4OSAxMS44Mzc4IDExLjkzMTggMTEuODc2NSAxMS44Njg5IDExLjkwMjhDMTEuODA1OSAxMS45MjkxIDExLjczODMgMTEuOTQyNCAxMS42NyAxMS45NDE5QzExLjYwMiAxMS45NDA4IDExLjUzNDkgMTEuOTI2MSAxMS40NzI2IDExLjg5ODVDMTEuNDEwNCAxMS44NzEgMTEuMzU0MyAxMS44MzEyIDExLjMwNzggMTEuNzgxNkMxMS4yMDk5IDExLjY3ODYgMTEuMTU0NyAxMS41NDIzIDExLjE1MzYgMTEuNDAwM0MxMS4xNTI0IDExLjI1ODIgMTEuMjA1MyAxMS4xMjEgMTEuMzAxNSAxMS4wMTY0TDE1LjU1OTQgNi41NDYxMUgxLjE2MjYxQzEuMDk1MiA2LjU0NDU5IDEuMDI4ODUgNi41MjkwOSAwLjk2Nzc0MSA2LjUwMDU5QzAuOTA2NjM0IDYuNDcyMSAwLjg1MjEwNiA2LjQzMTI0IDAuODA3NjA3IDYuMzgwNThDMC43MTI3MDQgNi4yNzY4NiAwLjY2MjI0NiA2LjE0MDA2IDAuNjY3MDY0IDUuOTk5NTZDMC42NjQ0NDcgNS44NTk3MiAwLjcxNDYzIDUuNzI0MDQgMC44MDc2MDcgNS42MTk1N0MwLjg1Mjc2MSA1LjU2OTk4IDAuOTA3Mjg1IDUuNTI5ODIgMC45NjgwMzcgNS41MDE0MUMxLjAyODc5IDUuNDczIDEuMDk0NTYgNS40NTY5IDEuMTYxNTcgNS40NTQwNEgxNS41NjE1TDExLjMwMDUgMC45ODQ3NjNDMTEuMjAxNSAwLjg4MTA4OCAxMS4xNDcgMC43NDI4NzIgMTEuMTQ4NSAwLjU5OTU3QzExLjE0OSAwLjQ1NjM0NyAxMS4yMDMzIDAuMzE4NTQgMTEuMzAwNSAwLjIxMzMzNkMxMS4zNzE2IDAuMTM4Mjc0IDExLjQ2MzkgMC4wODY2OTUyIDExLjU2NTEgMC4wNjU0ODg1QzExLjY2NjMgMC4wNDQyODE4IDExLjc3MTUgMC4wNTQ0NTcgMTEuODY2OCAwLjA5NDY1NUMxMS45MzAzIDAuMTIxNzIzIDExLjk4NzYgMC4xNjIzMjQgMTIuMDM2NSAwLjIxMjI5NUwxNy4xODM1IDUuNjE1NEMxNy4yNzA5IDUuNzA3OTggMTcuMzIzOSA1LjgyNzgxIDE3LjMzMzQgNS45NTQ3OVY2LjA0NzQ0QzE3LjMyMzcgNi4xNzQzOCAxNy4yNzA4IDYuMjk0MTYgMTcuMTgzNSA2LjM4NjgzSDE3LjE4MjVaIiBmaWxsPSIjMDQzQjdDIi8+Cjwvc3ZnPgo=' /></span>
                        <span *ngIf="disCol?.type === 'no-action'">{{ row[disCol.columnName]}}
                        </span>
                        <span *ngIf="disCol?.type === 'date'">{{ row[disCol.columnName] | date:'MM/dd/yyyy'}}
                        </span>
                        <span *ngIf="
                        disCol?.type === 'status'">
                            <button mat-flat-button [ngClass]="getCssName(row[disCol.columnName])"
                                class="ig2 button--small mat-button-base">{{row[disCol.columnName]}}</button>
                        </span>



                    </mat-cell>
                </ng-container>
            </div>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row ;let ind=index; columns: displayedColumns"> </mat-row> -->



        <ng-container matColumnDef="orderDate">
            <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.orderDate |date:'MM/dd/yyyy'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let row"> <button mat-flat-button [ngClass]="getCssName(row.status)"
                    class="ig2 button--small mat-button-base">{{row.status}}</button> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assignee">
            <mat-header-cell *matHeaderCellDef> Assigned To </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.assignee}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="taskType">
            <mat-header-cell *matHeaderCellDef> Task Action </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.taskType}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="displayName">
            <mat-header-cell *matHeaderCellDef> Ignite Task Name </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.displayName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef> Notes </mat-header-cell>
            <mat-cell *matCellDef="let row">
                <span class="edit-icon cr-pointer" id="btnView" *ngIf="row.totalNotesComments > 0"
                    (click)="viewNotes(row)"><span class="viewstyle">View</span>&nbsp; <img alt=""
                        src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxOCAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjE4MjUgNi4zODY4M0wxMi4wMzY1IDExLjc4ODlDMTEuOTg4OSAxMS44Mzc4IDExLjkzMTggMTEuODc2NSAxMS44Njg5IDExLjkwMjhDMTEuODA1OSAxMS45MjkxIDExLjczODMgMTEuOTQyNCAxMS42NyAxMS45NDE5QzExLjYwMiAxMS45NDA4IDExLjUzNDkgMTEuOTI2MSAxMS40NzI2IDExLjg5ODVDMTEuNDEwNCAxMS44NzEgMTEuMzU0MyAxMS44MzEyIDExLjMwNzggMTEuNzgxNkMxMS4yMDk5IDExLjY3ODYgMTEuMTU0NyAxMS41NDIzIDExLjE1MzYgMTEuNDAwM0MxMS4xNTI0IDExLjI1ODIgMTEuMjA1MyAxMS4xMjEgMTEuMzAxNSAxMS4wMTY0TDE1LjU1OTQgNi41NDYxMUgxLjE2MjYxQzEuMDk1MiA2LjU0NDU5IDEuMDI4ODUgNi41MjkwOSAwLjk2Nzc0MSA2LjUwMDU5QzAuOTA2NjM0IDYuNDcyMSAwLjg1MjEwNiA2LjQzMTI0IDAuODA3NjA3IDYuMzgwNThDMC43MTI3MDQgNi4yNzY4NiAwLjY2MjI0NiA2LjE0MDA2IDAuNjY3MDY0IDUuOTk5NTZDMC42NjQ0NDcgNS44NTk3MiAwLjcxNDYzIDUuNzI0MDQgMC44MDc2MDcgNS42MTk1N0MwLjg1Mjc2MSA1LjU2OTk4IDAuOTA3Mjg1IDUuNTI5ODIgMC45NjgwMzcgNS41MDE0MUMxLjAyODc5IDUuNDczIDEuMDk0NTYgNS40NTY5IDEuMTYxNTcgNS40NTQwNEgxNS41NjE1TDExLjMwMDUgMC45ODQ3NjNDMTEuMjAxNSAwLjg4MTA4OCAxMS4xNDcgMC43NDI4NzIgMTEuMTQ4NSAwLjU5OTU3QzExLjE0OSAwLjQ1NjM0NyAxMS4yMDMzIDAuMzE4NTQgMTEuMzAwNSAwLjIxMzMzNkMxMS4zNzE2IDAuMTM4Mjc0IDExLjQ2MzkgMC4wODY2OTUyIDExLjU2NTEgMC4wNjU0ODg1QzExLjY2NjMgMC4wNDQyODE4IDExLjc3MTUgMC4wNTQ0NTcgMTEuODY2OCAwLjA5NDY1NUMxMS45MzAzIDAuMTIxNzIzIDExLjk4NzYgMC4xNjIzMjQgMTIuMDM2NSAwLjIxMjI5NUwxNy4xODM1IDUuNjE1NEMxNy4yNzA5IDUuNzA3OTggMTcuMzIzOSA1LjgyNzgxIDE3LjMzMzQgNS45NTQ3OVY2LjA0NzQ0QzE3LjMyMzcgNi4xNzQzOCAxNy4yNzA4IDYuMjk0MTYgMTcuMTgzNSA2LjM4NjgzSDE3LjE4MjVaIiBmaWxsPSIjMDQzQjdDIi8+Cjwvc3ZnPgo=' />
                </span>
                <span class="edit-icon cr-pointer" id="btnView"
                    *ngIf="row.totalNotesComments == 0 || row.totalNotesComments == null">
                    <span class="viewstyle-none">None</span>
                </span>
            </mat-cell>
        </ng-container>

        <!-- Header and Row Declarations -->
        <mat-header-row
            *matHeaderRowDef="['orderDate','status','assignee','taskType','displayName', 'notes']"></mat-header-row>
        <mat-row (click)="highlight(row)" [ngClass]="{'highlight': selectedRowIndex == row.id}"
            *matRowDef="let row; columns: ['orderDate','status','assignee','taskType','displayName', 'notes']"></mat-row>
    </table>
    <div class="tb-pagination" *ngIf="dataSource.data.length > 0 && totalPageCount > 1">
        <lib-pagination #paginator [totalPages]="totalPageCount" (pageClicked)="onPageClick($event)"
            [currentPage]="currentPage">
        </lib-pagination>
    </div>
    <ng-container *ngIf="dataSource.data.length === 0">
        <p class="no-data">No data to display</p>
    </ng-container>

</mat-dialog-content>
<mat-dialog-content class="mat-typography mat-dialog-content notes" *ngIf="showViewTemplate">
    <section class="file__search__subnav">
        <div class="file__search__subnav__breadcrumb">
            <img name="left-arrow" class="left-arrow" (click)="viewBack()" alt=""
                src="./assets/icons/icon-arrow-left-dark.svg" />
            <span class="breadcrumb" name="breadcrumb">
                &nbsp;Notes
            </span>
        </div>
    </section>
    <section *ngIf="noteList && noteList.length>0" class="modal-height file__search__subnav">
        <div class="file-notes-list" *ngFor="let x of noteList">
            <div class="activity-log-card" id="activity-log-card-">
                <div class="common-notes-card" id="common-notes-card-">
                    <div class="common-notes-card--header">
                        <div class="common-notes-card--header--avatar ng-star-inserted" *ngIf="!x.isIgnite">
                            <div *ngIf="x.isImageValid && x?.profileImage">
                                <img [src]=x.profileImage (error)="x.isImageValid = false" alt="">
                            </div>
                            <div class="create-user-initials-text-icon" *ngIf="!x.isImageValid">
                                <span>{{x.createdUserInitials == 'Rule Admin' ? 'Ignite' :
                                    x.createdUserInitials}}</span>
                            </div>
                        </div>
                        <div class="common-notes-card--header--avatar ng-star-inserted" *ngIf="x.isIgnite">
                            <img src='assets/svg/icon-ignite-avatar.svg' alt="">
                        </div>
                        <div class="common-notes-card--header--content">
                            <div class="common-notes-card--name">
                                {{x.createdUser}}</div>
                            <div class="common-notes-card--meta">
                                <div class="common-notes-card--time">
                                    {{x.createdDate |date:'MM/dd/yyyy'}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="common-notes-card--body"><span>{{x.description}}</span><br>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-container *ngIf="noteList.length === 0">
        <p class="no-data">No data to display</p>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions" *ngIf="!showViewTemplate">
    <button mat-flat-button mat-dialog-close type="button"
        class="mat-focus-indicator ig2 button button--clear mat-flat-button mat-button-base btn-cancel">Cancel</button>

    <div *ngIf="data.sourceType=='1'">
        <button id="btnAddtask" mat-flat-button (click)="addNewTask()"
            class="ig2 button  button--small mat-button mat-button-base button--tertiary"><span
                class="mat-button-wrapper">
                <img id="imgUser" src="/assets/icons/icon-plus-dark.svg " alt="plus-icon" class="button__icon"> Add Task
            </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                class="mat-button-focus-overlay"></span></button>
        <button mat-flat-button class="ig2 button button--primary button--small mat-button mat-button-base"
            cdkFocusInitial (click)="processTask()" [disabled]="(!this.selectedTaskDetail)">Processing page &nbsp;<img
                alt=""
                src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjU0NjEgOC4zMDc5TDEwLjQyOTMgMTIuNjI5NkMxMC4zOTEyIDEyLjY2ODYgMTAuMzQ1NiAxMi42OTk2IDEwLjI5NTIgMTIuNzIwN0MxMC4yNDQ4IDEyLjc0MTcgMTAuMTkwNyAxMi43NTI0IDEwLjEzNjEgMTIuNzUyQzEwLjA4MTcgMTIuNzUxMSAxMC4wMjggMTIuNzM5MyA5Ljk3ODIgMTIuNzE3M0M5LjkyODQxIDEyLjY5NTIgOS44ODM1NiAxMi42NjM0IDkuODQ2MyAxMi42MjM3QzkuNzY3OTggMTIuNTQxNCA5LjcyMzg5IDEyLjQzMjMgOS43MjI5NyAxMi4zMTg2QzkuNzIyMDQgMTIuMjA1IDkuNzY0MzQgMTIuMDk1MiA5Ljg0MTMgMTIuMDExNkwxMy4yNDc3IDguNDM1MzNIMS43MzAxOEMxLjY3NjI2IDguNDM0MTEgMS42MjMxOCA4LjQyMTcxIDEuNTc0MjkgOC4zOTg5MUMxLjUyNTQxIDguMzc2MTIgMS40ODE3OCA4LjM0MzQzIDEuNDQ2MTggOC4zMDI5QzEuMzcwMjYgOC4yMTk5MiAxLjMyOTg5IDguMTEwNDkgMS4zMzM3NSA3Ljk5ODA4QzEuMzMxNjUgNy44ODYyMiAxLjM3MTggNy43Nzc2NyAxLjQ0NjE4IDcuNjk0MDlDMS40ODIzMSA3LjY1NDQyIDEuNTI1OTMgNy42MjIyOSAxLjU3NDUzIDcuNTk5NTdDMS42MjMxMyA3LjU3Njg0IDEuNjc1NzUgNy41NjM5NiAxLjcyOTM1IDcuNTYxNjdIMTMuMjQ5M0w5Ljg0MDQ3IDMuOTg2MjVDOS43NjEzMiAzLjkwMzMxIDkuNzE3NjkgMy43OTI3NCA5LjcxODg3IDMuNjc4MDlDOS43MTkzMyAzLjU2MzUxIDkuNzYyNzIgMy40NTMyNyA5Ljg0MDQ3IDMuMzY5MTFDOS44OTczNiAzLjMwOTA2IDkuOTcxMjEgMy4yNjc3OSAxMC4wNTIyIDMuMjUwODNDMTAuMTMzMSAzLjIzMzg2IDEwLjIxNzMgMy4yNDIgMTAuMjkzNSAzLjI3NDE2QzEwLjM0NDMgMy4yOTU4MiAxMC4zOTAxIDMuMzI4MyAxMC40MjkzIDMuMzY4MjdMMTQuNTQ2OSA3LjY5MDc2QzE0LjYxNjggNy43NjQ4MiAxNC42NTkyIDcuODYwNjkgMTQuNjY2OCA3Ljk2MjI3VjguMDM2MzlDMTQuNjU5MSA4LjEzNzk0IDE0LjYxNjcgOC4yMzM3NiAxNC41NDY5IDguMzA3OUgxNC41NDYxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==' /></button>
    </div>
    <div *ngIf="data.sourceType=='2'">
        <button id="btnAddtask" mat-flat-button (click)="LinkNewTaskEmail()"
            class="ig2 button  button--small mat-button mat-button-base button--tertiary"><span
                class="mat-button-wrapper">
                <img id="imgUser" src="/assets/icons/icon-plus-dark.svg " alt="plus-icon" class="button__icon">Link to
                New Task
            </span><span matripple="" class="mat-ripple mat-button-ripple"></span><span
                class="mat-button-focus-overlay"></span></button>
        <button mat-flat-button class="ig2 button button--primary button--small mat-button mat-button-base"
            cdkFocusInitial (click)="LinkprocessTaskEmail()" [disabled]="(!this.selectedTaskDetail)">Link to Selected
            Task
            &nbsp;<img alt=""
                src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjU0NjEgOC4zMDc5TDEwLjQyOTMgMTIuNjI5NkMxMC4zOTEyIDEyLjY2ODYgMTAuMzQ1NiAxMi42OTk2IDEwLjI5NTIgMTIuNzIwN0MxMC4yNDQ4IDEyLjc0MTcgMTAuMTkwNyAxMi43NTI0IDEwLjEzNjEgMTIuNzUyQzEwLjA4MTcgMTIuNzUxMSAxMC4wMjggMTIuNzM5MyA5Ljk3ODIgMTIuNzE3M0M5LjkyODQxIDEyLjY5NTIgOS44ODM1NiAxMi42NjM0IDkuODQ2MyAxMi42MjM3QzkuNzY3OTggMTIuNTQxNCA5LjcyMzg5IDEyLjQzMjMgOS43MjI5NyAxMi4zMTg2QzkuNzIyMDQgMTIuMjA1IDkuNzY0MzQgMTIuMDk1MiA5Ljg0MTMgMTIuMDExNkwxMy4yNDc3IDguNDM1MzNIMS43MzAxOEMxLjY3NjI2IDguNDM0MTEgMS42MjMxOCA4LjQyMTcxIDEuNTc0MjkgOC4zOTg5MUMxLjUyNTQxIDguMzc2MTIgMS40ODE3OCA4LjM0MzQzIDEuNDQ2MTggOC4zMDI5QzEuMzcwMjYgOC4yMTk5MiAxLjMyOTg5IDguMTEwNDkgMS4zMzM3NSA3Ljk5ODA4QzEuMzMxNjUgNy44ODYyMiAxLjM3MTggNy43Nzc2NyAxLjQ0NjE4IDcuNjk0MDlDMS40ODIzMSA3LjY1NDQyIDEuNTI1OTMgNy42MjIyOSAxLjU3NDUzIDcuNTk5NTdDMS42MjMxMyA3LjU3Njg0IDEuNjc1NzUgNy41NjM5NiAxLjcyOTM1IDcuNTYxNjdIMTMuMjQ5M0w5Ljg0MDQ3IDMuOTg2MjVDOS43NjEzMiAzLjkwMzMxIDkuNzE3NjkgMy43OTI3NCA5LjcxODg3IDMuNjc4MDlDOS43MTkzMyAzLjU2MzUxIDkuNzYyNzIgMy40NTMyNyA5Ljg0MDQ3IDMuMzY5MTFDOS44OTczNiAzLjMwOTA2IDkuOTcxMjEgMy4yNjc3OSAxMC4wNTIyIDMuMjUwODNDMTAuMTMzMSAzLjIzMzg2IDEwLjIxNzMgMy4yNDIgMTAuMjkzNSAzLjI3NDE2QzEwLjM0NDMgMy4yOTU4MiAxMC4zOTAxIDMuMzI4MyAxMC40MjkzIDMuMzY4MjdMMTQuNTQ2OSA3LjY5MDc2QzE0LjYxNjggNy43NjQ4MiAxNC42NTkyIDcuODYwNjkgMTQuNjY2OCA3Ljk2MjI3VjguMDM2MzlDMTQuNjU5MSA4LjEzNzk0IDE0LjYxNjcgOC4yMzM3NiAxNC41NDY5IDguMzA3OUgxNC41NDYxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==' /></button>
    </div>
</mat-dialog-actions>