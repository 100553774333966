import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SearchResultsDropdownComponent } from './components/search-results-dropdown/search-results-dropdown.component';
import { OutsideClickDirective } from './global/directives/outside-click.directive';
import { FileSearchResultsComponent } from './components/file-search-results/file-search-results.component';
import { ListPageSubHeaderModule } from '@igps/list-page-sub-header';
import { PageSubHeaderModule } from '@igps/page-sub-header';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FileSearchComponent } from './components/file-search/file-search.component';
import { PageHeaderModule } from '@igps/page-header';
import { MatDialogModule } from '@angular/material/dialog';
import { SpinnerModule } from '@igps/spinner';
import { FileInfoCardComponent } from './components/file-info-card/file-info-card.component';
import { InfoCardModule } from '@ui-core/info-card';
import { TransactionsInfoComponent } from './components/transactions-info/transactions-info.component';
import { HttpInterceptor } from './global/interceptor/header-interceptor.interceptor';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { PaginationModule } from '@ui-core/pagination';
import { StatusLabelModule } from '@ui-core/status-label';
import { LoaderModule } from '@ui-core/loader'
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ViewTaskPopupComponent } from './components/view-task-popup/view-task-popup.component';
import { SecurePortalBadgeComponent } from './components/secure-portal-badge/secure-portal-badge.component';
import { IconModule } from '@ui-core/icon';
import { EmailSearchComponent } from './components/email-search/email-search.component';
import { ParcelModule } from 'single-spa-angular/parcel';
import { FilterSelectModule } from '@igps/filter-select';
import { EmailActionPopupComponent } from './components/file-search/email-action-popup/email-action-popup.component';
import { MoveEmailPopupComponent } from './components/move-email-popup/move-email-popup.component';
import { EmailStatusComponent } from './components/email-status/email-status.component';
import { ConvertTimePipe, PipeModule } from '@igps/pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { ToastModule } from '@ui-core/toast';

@NgModule({
  declarations: [
    AppComponent,
    SearchResultsDropdownComponent,
    OutsideClickDirective,
    FileSearchComponent,
    FileSearchResultsComponent,
    FileInfoCardComponent,
    TransactionsInfoComponent,
    SecurePortalBadgeComponent,
    TimepickerComponent,
    ViewTaskPopupComponent, EmailSearchComponent, EmailActionPopupComponent, MoveEmailPopupComponent, EmailStatusComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ParcelModule,
    AppRoutingModule,
    FilterSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatSelectModule,
    MatFormFieldModule,
    HttpClientModule,
    MatFormFieldModule,
    ListPageSubHeaderModule,
    PageSubHeaderModule,
    PageHeaderModule,
    SpinnerModule,
    InfoCardModule,
    MatTableModule,
    MatButtonModule,
    MatSortModule,
    MatSelectModule,
    MatOptionModule,
    BrowserAnimationsModule,
    PaginationModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    StatusLabelModule,
    IconModule,
    CommonModule,
    PipeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToastModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true,
  },
  {
    provide: ErrorHandler,
    useClass: HttpInterceptor
  },
    DatePipe,
    ConvertTimePipe,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
